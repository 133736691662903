import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class Registrationres {
    status:any;
    msg:any;
    statusCode:any;

    // account list variables
    accountIdList:any=[];
    
    // wafIdList variables
    wafIdList:any=[];
}
