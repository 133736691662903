import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { DeletemacIdreq } from '../req/deletemac-idreq.model';

@Injectable({
  providedIn: 'root'
})
export class DeletemacIdserviceService {

  Url: string = "https://atikaxgfk6.execute-api.ap-south-1.amazonaws.com/dev/superadmin/clearwafid";
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':localStorage.getItem("sessiontoken"),
      'x-api-key':localStorage.getItem("clientid")
     
    })
  };
  http: any;
  constructor(private httpClient : HttpClient) { }
  deleteId(deletereq: DeletemacIdreq) {
    return this.httpClient.post(this.Url,deletereq,this.httpOptions);
  }

}
