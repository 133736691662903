import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Loginreq } from './req/loginreq.model';
import { Loginres } from './res/loginres.model';
import { LoginserviceService } from './service/loginservice.service';
import { Router } from '@angular/router';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  msg:any;
  msg1:any;


  constructor(private formBuilder: FormBuilder,private loginreq:Loginreq,private loginres:Loginres,private service:LoginserviceService,
    private router:Router) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      managerEmailId:['', Validators.required,],
      managerPassword: ['',Validators.required,],
  });
  }
  get f() { return this.loginForm.controls; }

  // Api call for Login
  onSubmit()
  {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }
  else{
    $('#wait').show();
    this.loginreq.userId=$('#managerEmailId').val();
    this.loginreq.password=$('#managerPassword').val();
    this.service.Userlogin(this.loginreq).subscribe(
      suc => { 
        this.onsuccess(suc);
        },
      err => {
        $('#wait').hide();
        this.msg="Unable to Login Please Contact Admin!";
        $('#myModal').modal('toggle');
        $('#myModal').modal('show');
      }
    );
     }
  }
  // Success Function For Login
  onsuccess(suc: any) {
    this.loginres=suc;
    $('#wait').hide();
    if(this.loginres.status=="1")
    {
      localStorage.setItem("sessiontoken",this.loginres.sessiontoken);
      localStorage.setItem("clientid",this.loginres.clientid);
      this.router.navigate(['/dashboard/adduser']);
    }
    else{
      this.msg1="Login Failed"
      this.router.navigate(['/login']);
    }
  }
  }
