import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DeletemacIdComponent } from './deletemac-id/deletemac-id.component';
import { ViewuserComponent } from './viewuser/viewuser.component';
import { ValidateOtpComponent } from './validate-otp/validate-otp.component';


const routes: Routes = [
  { path: '',
  redirectTo: 'login',
  pathMatch: 'full'
},
  { path: 'login',
   component: LoginComponent 
  },
  { 
    path: 'adduser',
     component: RegistrationComponent
   },
   { 
    path: 'dashboard',
     component: DashboardComponent,
     children: [
      {
        path:'deletemacid',
        component:DeletemacIdComponent
      },
      { 
        path: 'adduser',
         component: RegistrationComponent
       },
       { 
        path: 'viewuser',
         component: ViewuserComponent
       },
       {
         path:'validateotp',
         component:ValidateOtpComponent
       }
    ]
    },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
