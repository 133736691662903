import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

export class Registrationreq {
    eId:any;
    un:any;
    pswd:any;
    mn:any;
    aId:any;

    // accountlist variables
    clientId:any;
    action:any;

    // WAFID variables
    accountId:any;
}
