import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
  
export class ValidateOtpres {
    s:any;
    m:any;
    sc:any;
}
