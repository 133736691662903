import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class Viewuserres {

    cognitoUserList:any=[];
    accountId:any;
    emailId:any;
    userName:any;
    mobileNumber:any;
    status:any;
    msg:any;

    // update user Variables
    s:any;
    m:any;

}
