import { Component, OnInit } from '@angular/core';
import { RegistrationserviceService } from './service/registrationservice.service';
import { Registrationreq } from './req/registrationreq.model';
import { Registrationres } from './res/registrationres.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MustMatch } from '../_helpers/must-match.validator';
declare var jquery: any;
declare var $: any;


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  msg:any;
  accountIdList:any=[];
  WAFIdList:any=[];

  constructor(private service:RegistrationserviceService,private sgnupreq:Registrationreq,private sgnupres:Registrationres,
    private formBuilder: FormBuilder,private router:Router) { }

  ngOnInit() {
    this.fetchAccountListdata();
    this.registerForm = this.formBuilder.group({
      admin_name: ['', Validators.required],
      aid: ['', Validators.required],
      wafid:['', Validators.required],
      // admin_email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(minfytech)\.com$/),]],
      admin_email: ['', Validators.required],
      pwd: ['', [Validators.required, Validators.minLength(6)]],
      cnf_pwd: ['', Validators.required],
      phonenumber:['', Validators.required], 
  }, {
      validator: MustMatch('pwd', 'cnf_pwd'),
  },
  );
  }
  get f() { return this.registerForm.controls; }

  
  
  submitadminregisteration()
  {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
  else{
    $('#wait').show();
    this.sgnupreq.eId=$("#admin_email").val();
    localStorage.setItem("eId",$("#admin_email").val());
    this.sgnupreq.aId=$('#aid'). children("option:selected").val()+','+$('#wafid'). children("option:selected").val();
    this.sgnupreq.un=$("#admin_name").val();
    this.sgnupreq.pswd=$("#pwd").val();
    this.sgnupreq.mn=$("#phonenumber").val();
    this.service.AddUser(this.sgnupreq).subscribe(
      suc => { 
        this.success(suc);
        },
      err => {
          // alert(JSON.stringify(err));
          // $('#alertmsg').show();
          $('#wait').hide();
          localStorage.clear();
        this.msg="Session Expired !";
        $('#myModalerr').modal('toggle');
        $('#myModalerr').modal('show');
      }
    );
  }
 
}
  success(suc: any) {
   $('#wait').hide();
   this.sgnupres=suc;
   if(this.sgnupres.status=="1")
   {
    this.router.navigate(['/dashboard/validateotp'])
   }
   else
   {
    this.msg="Already User Exist !";
    $('#myModal').modal('toggle');
    $('#myModal').modal('show');
    this.router.navigate(['/dashboard/adduser'])
   }
  
  }

  // fetch Account list data
  fetchAccountListdata()
  {
    $('#wait').show();
    this.sgnupreq.clientId=localStorage.getItem("clientid");
    this.sgnupreq.action="fetchaccounts";
    this.service.fetchaccountlist(this.sgnupreq).subscribe(
      suc => { 
        this.fetchaccountsuccess(suc);
        },
      err => {
          // alert(JSON.stringify(err));
          // $('#alertmsg').show();
          $('#wait').hide();
          localStorage.clear();
          this.msg="Session Expired !";
          $('#myModalerr').modal('toggle');
          $('#myModalerr').modal('show');
      }
    );
  }
  fetchaccountsuccess(suc: any) {
    $('#wait').hide();
    this.sgnupres=suc;
    if(this.sgnupres.status=="1")
    {
      this.accountIdList=this.sgnupres.accountIdList;
    }
  }

  getDataBasedOnAccountId($event)
  {
    $('#wait').show();
   this.sgnupreq.clientId=localStorage.getItem("clientid");
    this.sgnupreq.action="fetchwafid";
    this.sgnupreq.accountId=$event.target.value;
    this.service.fetchaccountlist(this.sgnupreq).subscribe(
      suc => { 
        this.fetchWAFIDsuccess(suc);
        },
      err => {
          // alert(JSON.stringify(err));
          // $('#alertmsg').show();
          $('#wait').hide();
          localStorage.clear();
        this.msg="Session Expired !";
        $('#myModalerr').modal('toggle');
        $('#myModalerr').modal('show');
      }
    );
  }
  fetchWAFIDsuccess(suc: any) {
    $('#wait').hide();
    this.sgnupres=suc;
    if(this.sgnupres.status=="1")
    {
      this.WAFIdList=this.sgnupres.wafIdList;
    }
  }

  errorrouting()
  {
    $('#myModalerr').modal('toggle');
    $('#myModalerr').modal('hide');
    this.router.navigate(['/login']);
  }
}
