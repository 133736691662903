import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Registrationreq } from '../req/registrationreq.model';

@Injectable({
  providedIn: 'root'
})
export class RegistrationserviceService {

  Url: string = "https://atikaxgfk6.execute-api.ap-south-1.amazonaws.com/dev/superadmin/signup";
  AccountListUrl: string ="https://atikaxgfk6.execute-api.ap-south-1.amazonaws.com/dev/superadmin/fetchaccountlist";
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':localStorage.getItem("sessiontoken"),
      'x-api-key':localStorage.getItem("clientid")
     
    })
  };
  http: any;
  constructor(private httpClient : HttpClient) { }
  AddUser(regreq: Registrationreq) {
    return this.httpClient.post(this.Url, regreq, this.httpOptions);
  }
  fetchaccountlist(regreq: Registrationreq) {
    return this.httpClient.post(this.AccountListUrl, regreq, this.httpOptions);
  }

}
