import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class Loginreq {

  userId:any;
  password:any;
  
}
