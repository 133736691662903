import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class DeletemacIdres {

    status:any;
    msg:any;
}
