import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Viewuserreq } from '../req/viewuserreq.model';
import { Viewuserreqinactive } from '../reqinactive/viewuserreqinactive.model';

@Injectable({
  providedIn: 'root'
})
export class ViewuserserviceService {

  Url: string = "https://atikaxgfk6.execute-api.ap-south-1.amazonaws.com/dev/superadmin/fetchuserdetails";
  updateUrl: string = "https://atikaxgfk6.execute-api.ap-south-1.amazonaws.com/dev/superadmin/updateuserdetails";
  // deleteUrl: string ="https://atikaxgfk6.execute-api.ap-south-1.amazonaws.com/prod/superadmin/updateuserdetails";
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':localStorage.getItem("sessiontoken"),
      'x-api-key':localStorage.getItem("clientid")
     
    })
  };
  http: any;
  constructor(private httpClient : HttpClient) { }
  fetchUsers(viewreq: Viewuserreq) {
    return this.httpClient.post(this.Url,viewreq,this.httpOptions);
  }
  updateUsers(viewreq: Viewuserreq) {
    return this.httpClient.post(this.updateUrl,viewreq,this.httpOptions);
  }
  fetchInactiveUsers(viewinactivereq: Viewuserreqinactive) {
    return this.httpClient.post(this.Url,viewinactivereq,this.httpOptions);
  }
}
