import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Viewuserreq {

    clientId:any;
  //  update user variables
    eId:any;
    wafId:any;
    status:any;
    mobileNumber:any;
  // delete user variables
     action:any; 

}

