import { Component, OnInit } from '@angular/core';
import { DashboardserviceService } from './service/dashboardservice.service';
import { Dashboardreq } from './req/dashboardreq.model';
import { Dashboardres } from './res/dashboardres.model';
import { Router } from '@angular/router';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  userName:any;
  msg:any;
  constructor(private service:DashboardserviceService,private lgreq:Dashboardreq,private lgres:Dashboardres,private router:Router) { }

  ngOnInit() {
    
    this.userName=localStorage.getItem("clientid");
    $(document).ready(function () {

      $('#sidebarCollapse').on('click', function () {
       var act= $('#sidebar').toggleClass('active');
          // $("#content").removeAttr("style");
          if ($('#sidebar').hasClass('active')){
            $('#content').attr('style','margin-left:-20%');
            // $('#content').attr('style','width:100%;')
           }
          else{
            $('#content').attr('style','margin-left:0%');
           }
      });
  });
  }

  logoutusers()
  {
    $('#wait').show();
    this.lgreq.userId=localStorage.getItem("clientid");
    this.service.logoutUser(this.lgreq).subscribe(
      suc => { 
        this.onsuccess(suc);
        },
      err => {
        $('#wait').hide();
        localStorage.clear();
        this.msg="Session Expired";
        $('#myModalerr').modal('toggle');
        $('#myModalerr').modal('show');
      }
    );
     }
  onsuccess(suc: any) {
    $('#wait').show();
    this.lgres=suc;
    if(this.lgres.status=="1")
    {
      localStorage.clear();
      this.router.navigate(['/login']);

    }
    else{
    this.msg=this.lgres.msg;
    $('#myModal').modal('toggle');
     $('#myModal').modal('show');
    localStorage.clear();
    this.router.navigate(['/login']);
    }
  }
   errorrouting()
  {
    $('#myModalerr').modal('toggle');
    $('#myModalerr').modal('hide');
    this.router.navigate(['/login']);
  }

  }
