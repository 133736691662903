import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class Viewuserreqinactive {

    clientId:any;
    action:any;
}

   