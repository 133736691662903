import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class DeletemacIdreq {
    
    clientId:any;
    accountId:any;
}
