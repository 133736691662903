import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class Loginres {

  clientid:any;
  sessiontoken:any;
  message:any;
  status:any;
}
