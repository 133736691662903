import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidateOtpreq } from './req/validate-otpreq.model';
import { ValidateOtpres } from './res/validate-otpres.model';
import { ValidateOtpserviceService } from './service/validate-otpservice.service';
import { Router } from '@angular/router';
declare var jquery: any;
declare var $: any;


@Component({
  selector: 'app-validate-otp',
  templateUrl: './validate-otp.component.html',
  styleUrls: ['./validate-otp.component.scss']
})
export class ValidateOtpComponent implements OnInit {
  validateOtpForm:FormGroup
  submitted=false;
  msg:any;

  constructor(private formBuilder: FormBuilder,private otpreq:ValidateOtpreq,private otpres:ValidateOtpres,private service:ValidateOtpserviceService,
    private router:Router) { }

  ngOnInit() {
    this.validateOtpForm = this.formBuilder.group({
      otp:['', Validators.required,],
  });
  }
  get f() { return this.validateOtpForm.controls; }

  // Api call for Login
  ValidateOtp()
  {
    this.submitted = true;

    // stop here if form is invalid
    if (this.validateOtpForm.invalid) {
        return;
    }
  else{
    $('#wait').show();
    this.otpreq.eId=localStorage.getItem('eId');
    this.otpreq.otp=$('#otp').val();
    this.service.OtpValidation(this.otpreq).subscribe(
      suc => { 
        this.onsuccess(suc);
        },
      err => {
        $('#wait').hide();
        localStorage.clear();
        this.msg="Session Expired !";
        $('#myModalerr').modal('toggle');
        $('#myModalerr').modal('show');
      }
    );
     }
  }
  // Success Function For Login
  onsuccess(suc: any) {
    this.otpres=suc;
    $('#wait').hide();
    if(this.otpres.s=="1")
    {
      this.router.navigate(['/dashboard/viewuser'])
    }
    else{
      this.router.navigate(['/dashboard/validateotp']);
      this.msg="Please Enter Valid Otp";
      $('#myModal').modal('toggle');
      $('#myModal').modal('show');

    }
  }

  errorrouting()
  {
    $('#myModalerr').modal('toggle');
    $('#myModalerr').modal('hide');
    this.router.navigate(['/login']);
  }
  }
