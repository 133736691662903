import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
  
export class ValidateOtpreq {
    otp:any;
    eId:any;

}
