import { Component, OnInit } from '@angular/core';
import { ViewuserserviceService } from './service/viewuserservice.service';
import { Viewuserreq } from './req/viewuserreq.model';
import { Viewuserres } from './res/viewuserres.model';
import { DeletemacIdreq } from '../deletemac-id/req/deletemac-idreq.model';
import { DeletemacIdres } from '../deletemac-id/res/deletemac-idres.model';
import { DeletemacIdserviceService } from '../deletemac-id/service/deletemac-idservice.service';
import { Router } from '@angular/router';
import { Viewuserreqinactive } from './reqinactive/viewuserreqinactive.model';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-viewuser',
  templateUrl: './viewuser.component.html',
  styleUrls: ['./viewuser.component.scss']
})
export class ViewuserComponent implements OnInit {

  clientid:any;
  msg:any;
  details: any[];
  constructor(private service:ViewuserserviceService,private viewreq:Viewuserreq,private viewres:Viewuserres,
    private dtlreq:DeletemacIdreq,private dtlres:DeletemacIdres,private dtlservice:DeletemacIdserviceService,private router:Router,
    private viewinactivereq:Viewuserreqinactive) { }

  ngOnInit() {
    $(document).ready(function(){
      $("#myInput").on("keyup", function() {
        var value = $(this).val().toLowerCase();
        $("#displaykeys tr").filter(function() {
          $('#headertable').show();
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
      });
    });
   
    this.fetchUserDetails(); 
  }

  fetchUserDetails()
  {
    $('#wait').show();
    this.viewreq.clientId=localStorage.getItem('clientid');
    this.service.fetchUsers(this.viewreq).subscribe(
      suc => { 
        this.onsuccess(suc);
        },
      err => {
        $('#wait').hide();
        localStorage.clear();
        this.msg="Session Expired !";
        $('#myModalerr').modal('toggle');
        $('#myModalerr').modal('show');
      }
    );
     }
  onsuccess(suc: any) {
    $('#wait').hide();
    this.viewres=suc;
    this.details=[];
    if(this.viewres.status=="1")
    {
      for(var i=0;i<this.viewres.cognitoUserList.length;i++)
      {
        $('#displaykeys').show();
        $('#headertable').show();
        $('#myInput').show();
        var d={"sno":i+1,"accountId":this.viewres.cognitoUserList[i].accountId,"emailId":this.viewres.cognitoUserList[i].emailId,
        "userName":this.viewres.cognitoUserList[i].userName,"isActive":this.viewres.cognitoUserList[i].isActive,"mobileNumber":this.viewres.cognitoUserList[i].mobileNumber
        ,"wafId":this.viewres.cognitoUserList[i].wafId,}
        this.details.push(d);
      }
    }
    else{
      $('#notfound').show();
    }
  }
  dataFunction(accountId,wafId,emailId,$event)
  {
    localStorage.setItem("clearmacaccountId",accountId);
    localStorage.setItem("clearwafId",wafId);
    localStorage.setItem("clearmacemailId",emailId);
    this.clientid=emailId;
    $('#myModal1').modal('toggle');
    $('#myModal1').modal('show');
  }

  clearMacId()
  {
    $('#wait').show();
    this.dtlreq.clientId=localStorage.getItem("clearmacemailId");
    this.dtlreq.accountId=localStorage.getItem("clearmacaccountId")+','+localStorage.getItem("clearwafId");
    this.dtlservice.deleteId(this.dtlreq).subscribe(
      suc => { 
        this.success(suc);
        },
      err => {
        $('#wait').hide();
        localStorage.clear();
        this.msg="Session Expired !";
        $('#myModalerr').modal('toggle');
        $('#myModalerr').modal('show');
      }
    );
     }

  // Success Function For Delete ID 
    success(suc: any) {
    this.dtlres=suc;
    $('#wait').hide();
    $('#myModal1').modal('toggle');
      $('#myModal1').modal('hide');

    if(this.dtlres.status=="1")
    {
      // alert(this.dtlres.msg);
      this.msg=this.dtlres.msg;
      $('#myModal').modal('toggle');
      $('#myModal').modal('show');
      
    }
    else{
      // alert(this.dtlres.msg);
      this.msg=this.dtlres.msg;
      $('#myModal').modal('toggle');
      $('#myModal').modal('show');

    }
  
  }
  editFunction(accountId,wafId,emailId,userName,isActive,mobileNumber,$event)
  {
    $('#myModal2').modal('toggle');
    $('#myModal2').modal('show');
    $('#eId').val(emailId);
    $('#username').val(userName);
    $('#mn').val(mobileNumber);
    $('#aId').val(accountId);
    $('#wafId').val(wafId);
    $('#status').val(isActive);
    
  }

  EditUserDetails()
  {
    $('#wait').show();
    this.viewreq.eId= $('#eId').val();
    this.viewreq.mobileNumber=$('#mn').val();
    this.viewreq.wafId= $('#aId').val()+','+$('#wafId').val();
    this.viewreq.status=$('#status'). children("option:selected").val();
    this.service.updateUsers(this.viewreq).subscribe(
      suc => { 
        this.updatesuccess(suc);
        },
      err => {
        $('#wait').hide();
        localStorage.clear();
        this.msg="Session Expired !";
        $('#myModalerr').modal('toggle');
        $('#myModalerr').modal('show');
      }
    );
  }
  updatesuccess(suc:any)
  {
    $('#wait').hide();
    this.viewres=suc;
    $('#myModal2').modal('toggle');
    $('#myModal2').modal('hide');
    if(this.viewres.s=="1")
    {
      this.msg="User Details Updated Successfully";
      $('#myModal').modal('toggle');
      $('#myModal').modal('show');
      this.fetchUserDetails();
    }
    else{
      this.msg="User Details Not Updated Please try Again !";
      $('#myModal').modal('toggle');
      $('#myModal').modal('show'); 
    }
  }

  deleteFunction(emailId,$event)
  {
    localStorage.setItem("deleteemailId",emailId)
    this.clientid=emailId;
    $('#myModal3').modal('toggle');
    $('#myModal3').modal('show');
  }

  deleteuserdata()
  {
    $('#wait').show();
    this.viewreq.eId=localStorage.getItem("deleteemailId");
    this.viewreq.action="delete";
    this.service.updateUsers(this.viewreq).subscribe(
      suc => { 
        this.deletesuccess(suc);
        },
      err => {
        $('#wait').hide();
        localStorage.clear();
        this.msg="Session Expired !";
        $('#myModalerr').modal('toggle');
        $('#myModalerr').modal('show');
      }
    );
  }
  deletesuccess(suc: any) {
   $('#wait').hide();
   this.viewres=suc;
   $('#myModal3').modal('toggle');
   $('#myModal3').modal('hide');
   if(this.viewres.s=="1")
   {
    this.msg="User Deleted Successfully";
    $('#myModal').modal('toggle');
    $('#myModal').modal('show');
    this.fetchUserDetails();
   }
   else{
    this.msg="User Not Deleted Please try Again!";
    $('#myModal').modal('toggle');
    $('#myModal').modal('show');
   }
  }

  errorrouting()
  {
    $('#myModalerr').modal('toggle');
    $('#myModalerr').modal('hide');
    this.router.navigate(['/login']);
  }
  
  fetchInactiveUserDetails()
  {
    $('#wait').show();
    this.viewinactivereq.clientId=localStorage.getItem('clientid');
    this.viewinactivereq.action="Inactive";
    this.service.fetchInactiveUsers(this.viewinactivereq).subscribe(
      suc => { 
        this.fetchinactiveusersuccess(suc);
        },
      err => {
        $('#wait').hide();
        localStorage.clear();
        this.msg="Session Expired !";
        $('#myModalerr').modal('toggle');
        $('#myModalerr').modal('show');
      }
    );
     }

 
     fetchinactiveusersuccess(suc:any)
     {
      $('#wait').hide();
      this.viewres=suc;
      this.details=[];
      if(this.viewres.status=="1")
      {
        for(var i=0;i<this.viewres.cognitoUserList.length;i++)
        {
          $('#displaykeys').show();
          $('#headertable').show();
          $('#myInput').show();
          var d={"sno":i+1,"accountId":this.viewres.cognitoUserList[i].accountId,"emailId":this.viewres.cognitoUserList[i].emailId,
          "userName":this.viewres.cognitoUserList[i].userName,"isActive":this.viewres.cognitoUserList[i].isActive,"mobileNumber":this.viewres.cognitoUserList[i].mobileNumber
          ,"wafId":this.viewres.cognitoUserList[i].wafId,}
          this.details.push(d);
        }
      }
      else{
        $('#notfound').show();
      }
     }
    checkActiveOrInactive()
    {
      if($('#myCheck').is(":checked")){
        this.fetchInactiveUserDetails()
    }
    else if(!$('#myCheck').is(":checked")){
        this.fetchUserDetails();
    }
    else{
       this.fetchUserDetails();
    }
    }
     
}