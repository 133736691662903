import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DeletemacIdreq } from './req/deletemac-idreq.model';
import { DeletemacIdres } from './res/deletemac-idres.model';
import { DeletemacIdserviceService } from './service/deletemac-idservice.service';
import { Router } from '@angular/router';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-deletemac-id',
  templateUrl: './deletemac-id.component.html',
  styleUrls: ['./deletemac-id.component.scss']
})
export class DeletemacIdComponent implements OnInit {

  deleteIdForm:FormGroup
  submitted=false;
  msg: string;

  constructor(private formBuilder:FormBuilder,private dtlreq:DeletemacIdreq,private dtlres:DeletemacIdres,
    private service:DeletemacIdserviceService,private router:Router ) { }

  ngOnInit() {
    this.deleteIdForm = this.formBuilder.group({
      aId:['', Validators.required,],
  });
  }
  get f() { return this.deleteIdForm.controls; }

  // Api call for Login
  deleteIddetails() 
   {
    this.submitted = true;

    // stop here if form is invalid
    if (this.deleteIdForm.invalid) {
      // alert("error")
        return;
    }
  else{
    $('#wait').show();
    this.dtlreq.clientId=$('#aId').val();
    this.service.deleteId(this.dtlreq).subscribe(
      suc => { 
        this.onsuccess(suc);
        },
      err => {
        $('#wait').hide();
        this.msg="Please try Again";
        $('#myModal').modal('toggle');
        $('#myModal').modal('show');
      }
    );
     }
  }
  // Success Function For Delete ID 
  onsuccess(suc: any) {
    this.dtlres=suc;
    $('#wait').hide();
    if(this.dtlres.status=="1")
    {
      // alert(this.dtlres.msg);
      this.msg=this.dtlres.msg;
      $('#myModal').modal('toggle');
      $('#myModal').modal('show');
      
    }
    else{
      // alert(this.dtlres.msg);
      this.msg=this.dtlres.msg;
      $('#myModal').modal('toggle');
      $('#myModal').modal('show');

    }
  }

  }
